<template>
  <nav class="navigation">
    <div class="nav">
      <ul class="nav-list">
        <li
          v-for="navItem in navigationList"
          :key="navItem.name"
          class="navLink"
        >
          <!-- Using v-if to differentiate between regular and telephone links -->
          <a
            v-if="!navItem.isPhone"
            :href="navItem.href"
            class="subtext"
            v-smooth-scroll
            >{{ $t(navItem.name) }}</a
          >
          <!-- Special case for the phone number -->
          <a v-else :href="navItem.href" class="subtext bold">{{
            navItem.display
          }}</a>
        </li>
      </ul>
      <div
        class="lang-swiper"
        v-if="$route.path === '/en' || $route.path === '/esp'"
      ></div>
    </div>
  </nav>
</template>

<script setup>
const navigationList = [
  { name: "services", href: "#services" },
  { name: "feedbacks", href: "#feedbacks" },
  { name: "ourBlog", href: "#blog" },
  {
    name: "contactUs",
    href: "#contact",
  },
  {
    name: "contactPhone",
    display: "+1 (305) 632-3687",
    href: "tel:+13056323687",
    isPhone: true,
  },
];
</script>

<style scoped lang="scss">
.nav-list {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .subtext {
    color: white;
    font-size: 16px;
    text-transform: uppercase;
  }
}
.bold {
  font-weight: 600;
  text-decoration: underline;
}
.nav {
  display: flex;
  justify-content: space-between;
}
.navigation {
  @media screen and (min-width: 1440px) {
    width: 53%;
  }
}
</style>

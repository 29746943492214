<template>
  <div class="full">
    <div id="navigation" class="container">
      <div class="au-content">
        <div class="au-text">
          <h4 class="h2">Navigation installation and repair:</h4>
          <h5 class="subtext mb-desk">
            Installing a marine navigation system on a vessel is an important
            step to ensure safe navigation and accurate positioning. This
            process includes the installation of GPS receiver, echo sounder,
            radar and other navigation aids. First of all, the optimum location
            for antennas and sensors is determined, maximizing the efficiency of
            the system. After the physical installation, programming and
            calibration of the devices begins to accurately determine
            coordinates, water depth and obstacle detection. The entire process
            is carried out in compliance with safety standards and marine
            navigation regulations.
          </h5>
        </div>
        <div class="imgs-container">
          <div class="subtext mb">
            <ul class="list">
              <li class="list-items">RADAR</li>
              <li class="list-items">ECDIS</li>
              <li class="list-items">GYRO</li>
              <li class="list-items">AIS</li>
              <li class="list-items">GPS / DGPS</li>
              <li class="list-items">MAGNETIC COMPASS</li>
              <li class="list-items">ECHOSOUNDER</li>
              <li class="list-items">SPEEDLOG</li>
              <li class="list-items">GPS CHARTPLOTTER</li>
              <li class="list-items">BNWAS</li>
              <li class="list-items">VDR / SVDR</li>
              <li class="list-items">AUTOPILOT</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="img-container container">
      <img :src="img.icon" v-for="img in imgs" :key="img" width="300" />
    </div>
  </div>
</template>
<script setup>
const imgs = [
  {
    icon: require("@/assets/nav/Screenshot 2024-02-05 at 22.45.10.png"),
    id: 1,
  },
  {
    icon: require("@/assets/nav/Screenshot 2024-02-05 at 22.45.22.png"),
    id: 2,
  },
  {
    icon: require("@/assets/nav/Screenshot 2024-02-05 at 22.45.38.png"),
    id: 3,
  },
  {
    icon: require("@/assets/nav/Screenshot 2024-02-05 at 22.45.54.png"),
    id: 4,
  },
  {
    icon: require("@/assets/nav/Screenshot 2024-02-05 at 22.46.15.png"),
    id: 5,
  },
  {
    icon: require("@/assets/nav/Screenshot 2024-02-05 at 22.46.40.png"),
    id: 6,
  },
  {
    icon: require("@/assets/nav/Screenshot 2024-02-05 at 22.46.54.png"),
    id: 7,
  },
  {
    icon: require("@/assets/nav/Screenshot 2024-02-05 at 22.47.10.png"),
    id: 8,
  },
  {
    icon: require("@/assets/nav/Screenshot 2024-02-05 at 22.47.18.png"),
    id: 9,
  },
  {
    icon: require("@/assets/nav/Screenshot 2024-02-05 at 22.47.32.png"),
    id: 11,
  },
  {
    icon: require("@/assets/nav/Screenshot 2024-02-05 at 22.47.57.png"),
    id: 11,
  },
  {
    icon: require("@/assets/nav/Screenshot 2024-02-05 at 22.48.09.png"),
    id: 11,
  },
];
</script>
<style lang="scss" scoped>
.imgs-container {
  img {
    display: block;
    margin: 0 auto;
  }
}
.img-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  img {
    display: block;
    margin: 0 auto 20px auto;
    max-width: 420px;
    width: 48%;

    @media screen and (min-width: 1440px) {
      margin-bottom: 30px;
      width: 30%;
    }
  }
}
#navigation {
  margin: 40px auto;
  .list li {
    list-style-type: none;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    padding: 3px 0 3px 28px;
    font-weight: 500;
    font-family: "Outfit";
    background: url("../assets/icons/boat-anchor.svg");
    background-repeat: no-repeat;
  }
  ul.list li {
    background-position: left 4px;
  }
  .h2 {
    position: relative;
    padding-left: 12px;
    margin-bottom: 32px;
    span {
      display: block;
    }
    @media screen and (min-width: 1440px) {
      margin-bottom: 30px;
    }
  }
  ul {
    margin-top: 20px;
  }
  .h2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 36px;
    background-color: $main-blue;
    transition: all 1s;
  }
  .full {
    position: relative;
  }
  .mb-desk {
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
      margin-bottom: 20px;
    }
  }

  .mb {
    margin-bottom: 20px;
  }
  .servicesForList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;

    li {
      width: 44%;
      margin-bottom: 40px;
      @media screen and (min-width: 768px) {
        width: 20%;
        margin-bottom: 0;
      }

      img {
        margin: 0 auto;
        @media screen and (min-width: 390px) {
          width: 60px;
        }
        @media screen and (min-width: 768px) {
          width: 50px;
          margin-bottom: 15px;
        }
      }

      .h4 {
        text-align: center;
      }
    }

    @media screen and (min-width: 768px) {
      flex-wrap: nowrap;
      width: 68%;
    }
    @media screen and (min-width: 1440px) {
      width: 100%;
    }
  }

  .servicesForList li:nth-last-of-type(-n + 2) {
    margin-bottom: 0;
  }

  .servicesForList li:first-child {
    @media screen and (min-width: 768px) {
      order: 1;
    }
  }

  .servicesForList li:nth-child(2) {
    @media screen and (min-width: 768px) {
      order: 2;
    }
  }

  .servicesForList li:nth-child(3) {
    @media screen and (min-width: 768px) {
      order: 4;
    }
  }

  .servicesForList li:nth-child(4) {
    @media screen and (min-width: 768px) {
      order: 3;
    }
  }
  .servicesFor.container {
    @media screen and (min-width: 1440px) {
      width: 100%;
      margin-top: 45px;
    }
  }
  .au-content {
    display: flex!important;
    align-items: center!important;
    @media screen and (min-width: 1440px) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
  .au-text {
    @media screen and (min-width: 1440px) {
      display: flex;
      flex-direction: column;
      width: 51%;
    }
    @media screen and (min-width: 1920px) {
      width: 75%;
    }
  }
}
</style>

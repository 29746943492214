<template>
  <div id="services">
    <div class="servicesFor container mobile tablet"></div>
    <div class="services">
      <ul class="servicesList">
        <li class="serviceList-item" v-for="item in services" :key="item">
          <router-link class="content" :to="item.path">
            <div class="main-content">
              <img class="service" :src="item.icon" />
              <div class="text">
                <span class="title-main">
                  {{ $t(item.mainTitle) }}
                </span>
                <p>{{ $t(item.title) }}</p>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="partners container">
      <div class="container">
        <h4 class="h3 brands">
          Mercury/Yamaha/Suzuki/Honda/Garmin/Raymarine/Simrad/Furuno/Sailor/SeaTel
        </h4>
      </div>
    </div>
  </div>
</template>

<script setup>
const services = [
  {
    icon: require("@/assets/category/engi.png"),
    mainTitle: "Engine and Generator",
    title:
      "Onboard engines/Generators/Onboard repair/Transmission/Steering systems/Hydraulic systems/Sensors Engine mounts/Leaks/Oil lines",
    example: "electricalExamples",
    path: "/engines",
    id: 4,
  },

  {
    icon: require("@/assets/category/navigation.png"),
    mainTitle: "Navigation and communication",
    title:
      "AIS/Navigation/Communication/GPS/Radar/Autopilot/Fish Finding Systems/NEMA Systems/Maritime Satellite Communications",
    example: "instaExamples",
    path: "/navigation",
    id: 1,
  },

  {
    icon: require("@/assets/category/electrical.png"),
    mainTitle: "Electrical",
    title:
      "Battery banks/Electrical wiring/Generators/Charging systems/Motors/Electrical Troubleshooting",
    example: "electricalExamples",
    path: "/electrical",
    id: 3,
  },

  {
    icon: require("@/assets/category/light.png"),
    mainTitle: "Lighting",
    title:
      "Deck Lighting/Running lights/Navigation lights/Waterproof housing lighting/Engine room light/Underwater lights",
    path: "/lighting",
    example: "radioExamples",
    id: 2,
  },
  {
    icon: require("@/assets/category/video.png"),
    mainTitle: "Sound, Video, Alarm",
    title:
      "Waterproof Sound Systems/Radio/Audio/Speakers/Satellite TV/Wi-Fi Amplifiers/Smoke detectors",
    example: "satelineExamples",
    path: "/video",
    id: 5,
  },

  {
    icon: require("@/assets/category/support.png"),
    mainTitle: "Water pumps and water systems",
    title:
      "Installation and repair of the water supply system/Replacement of taps/Water tanks/Boat watertightness",
    path: "/pumps",
    text: "electricalText",
    example: "electricalExamples",
    id: 6,
  },
];
</script>
<style scoped lang="scss">
#services {
  .h2 {
    margin-bottom: 60px;
    text-align: center;
  }

  .servicesForList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;

    li {
      width: 44%;
      @media screen and (min-width: 768px) {
        width: 20%;
        margin-bottom: 0;
      }
      img {
        margin: 0 auto;
        @media screen and (min-width: 390px) {
          width: 60px;
        }
        @media screen and (min-width: 768px) {
          width: 50px;
          margin-bottom: 15px;
        }
      }

      .h4 {
        text-align: center;
      }
    }

    @media screen and (min-width: 768px) {
      flex-wrap: nowrap;
      width: 68%;
      max-width: 524px;
    }
  }

  .servicesForList li:nth-last-of-type(-n + 2) {
    margin-bottom: 0;
  }

  .services {
    background-image: url("@/assets/icons/transp-bg.png");
    background-repeat: no-repeat;
    background-position: left;
    margin-top: 80px;
    @media screen and (min-width: 768px) {
      margin-top: 120px;
    }
    @media screen and (min-width: 1440px) {
      margin-top: 160px;
    }
  }

  .servicesList {
    border-top: 1px solid $main-blue;
    padding-bottom: 30px;
    @media screen and (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      padding: 0;
      max-width: 720px;
    }
    @media screen and (min-width: 1440px) {
      margin: 0 auto;
      max-width: 1110px;
      border-top: none;
      justify-content: space-between;
    }
    .top-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @media screen and (min-width: 768px) {
        width: 90%;
      }
      .h3 {
        width: 92%;
      }
    }

    .serviceList-item {
      padding: 0 20px 30px 20px;
      border-bottom: 1px solid $main-blue;
      @media screen and (min-width: 400px) {
        padding: 40px;
      }
      @media screen and (min-width: 768px) {
        padding: 40px 24px;
        margin-top: 0;
        width: 50%;
      }
      @media screen and (min-width: 1440px) {
        padding: 0;
        width: 30%;
        margin-bottom: 80px;
        //max-width: 253px;
        border-bottom: none;
      }
      .content {
        width: 83%;
        margin: 0 auto;
        max-width: 400px;
        cursor: pointer;
        @media screen and (min-width: 768px) {
          margin: 0 auto;
        }
        .main-content {
          position: relative;
          @media screen and (min-width: 768px) {
            margin: 0 auto;
            width: 100%;
          }
          img {
            width: 100%;
            max-height: 345px;
          }
          .text {
            color: white;
            padding: 14px;
            text-align: center;
            background-color: $main-blue;
            font-size: 14px;
            @media screen and (min-width: 768px) {
              font-size: 16px;
            }
          }
        }
        @media screen and (min-width: 768px) {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
        }

        .h3 {
          margin-bottom: 20px;
        }

        .subtext {
          color: $main-blue;
          font-size: 16px;
        }
        .bold {
          margin-bottom: 30px;
          text-transform: uppercase;
          font-weight: 700;
          margin-top: 20px;
        }
      }
      .content:hover {
        transform: scale(1.05);
      }
    }

    .serviceList-item:nth-child(1) {
      margin-top: 0;
      @media screen and (min-width: 768px) {
        border-right: 1px solid $main-blue;
        border-left: 1px solid $main-blue;
      }
      @media screen and (min-width: 1440px) {
        border-right: none;
        border-left: none;
      }
    }
    .serviceList-item:nth-child(3) {
      margin-top: 0;
      @media screen and (min-width: 768px) {
        border-right: 1px solid $main-blue;
        border-left: 1px solid $main-blue;
        padding: 40px 24px;
      }
      @media screen and (min-width: 1440px) {
        border: none;
        padding: 0;
        border-left: 0;
      }
    }

    .serviceList-item:nth-child(5) {
      @media screen and (min-width: 768px) {
        border-right: 1px solid $main-blue;
        border-left: 1px solid $main-blue;
      }
      @media screen and (min-width: 1440px) {
        border: none;
        padding: 0;
        border-left: 0;
      }
    }
    .serviceList-item:nth-child(even) {
      @media screen and (min-width: 768px) {
        border-right: 1px solid $main-blue;
      }
      @media screen and (min-width: 1440px) {
        border-right: none;
      }
    }

    //.serviceList-item:nth-child(even) .content {
    //  display: flex;
    //  flex-wrap: wrap;
    //  flex-direction: column;
    //  margin: 0 0 0 auto;
    //  align-items: flex-end;
    //  @media screen and (min-width: 768px) {
    //    margin: 0;
    //    align-items: flex-start;
    //  }
    //
    //  .h3 {
    //    text-align: right;
    //    @media screen and (min-width: 768px) {
    //      text-align: left;
    //    }
    //  }
    //
    //  .subtext {
    //    text-align: right;
    //    @media screen and (min-width: 768px) {
    //      text-align: left;
    //    }
    //  }
    //}
  }

  .orange {
    background-color: $main-orange;
  }
  .title-main {
    text-transform: uppercase;
    margin-bottom: 10px;
    display: block;
    font-weight: 900;
  }
  .brands {
    display: block;
    margin: 0 auto;
  }
}
</style>

<template>
  <header>
    <div class="header container">
      <router-link to="/" class="link">
        <img
          src="@/assets/logo.svg"
          class="logo"
          :class="{ exp: $route.path !== '/en' && $route.path !== '/esp' }"
        />
      </router-link>
      <Slide class="mobile">
        <Navigation />
      </Slide>
      <NavigationDesktop class="desktop" />
    </div>
  </header>
</template>

<script>
import { Slide } from "vue3-burger-menu";
import Navigation from "@/components/Header/Navigation/Navigation";
import NavigationDesktop from "@/components/Header/Navigation/NavigationDesktop";

export default {
  components: {
    NavigationDesktop,
    Slide,
    Navigation,
  },
};
</script>

<style lang="scss">
body {
  position: relative;
}
header {
  background-color: #15263c;
  //position: absolute;
  //z-index: 99999;
  width: 100%;
  .header {
    display: block;
    margin: 0 auto !important;
    background-color: #15263c;
    .exp {
      //position: relative !important;
    }

    .link {
      @media screen and (min-width: 1440px) {
        width: 150px;
        display: block;
        img {
          width: 100%;
        }
      }
    }

    .bm-burger-bars {
      background-color: $white;
    }

    .bm-menu {
      background-color: $white;
    }

    .bm-burger-button {
      width: 21px;
      height: 15px;
      top: 38px;
      z-index: 999;
      @media screen and (min-width: 390px) {
        width: 26px;
        height: 21px;
        left: 39px;
        top: 41px;
      }
    }

    .bm-item-list {
      margin: 65px 0 0 0;

      .centered {
        padding: 0 20px;
      }
    }

    .bm-cross {
      background: black;
      width: 2px !important;
      height: 17px !important;
    }

    .cross-style {
      top: 30px;
      left: 20px;
    }

    @media screen and (min-width: 1440px) {
      display: flex;
      padding: 10px 0;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>

<style scoped lang="scss">
.logo {
  position: absolute;
  top: 20px;
  left: calc(50% - 42px);
  z-index: 9;
  @media screen and (min-width: 390px) {
    width: 100px;
  }
  @media screen and (min-width: 1440px) {
    position: static;
    width: 9%;
  }
}
</style>

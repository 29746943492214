<template>
  <div class="full">
    <div id="micro" class="container">
      <div class="au-content">
        <div class="au-text">
          <h4 class="h2">
            Maintenance of boat outboard and inboard
            <span>motor systems, hydraulics and generators </span>
          </h4>
          <h5 class="subtext mb-desk eng-cont">
            Your motor is our business! Ensure the reliability of your
            watercraft with our boat motor repair services. Our skilled
            mechanics are ready to restore and maintain your motor in perfect
            condition. Professional Repair: Regardless of make and model, we
            specialize in quality motor repair. We will promptly identify and
            repair any malfunctions, returning your motor to full performance.
            Thorough Diagnosis: Our technicians will perform a thorough
            diagnosis to identify the root cause of the problem. This will allow
            us to provide you with the best recommendations to maintain and
            prevent future problems. Urgent Repair: We understand that your boat
            is not just a means of transportation, it's part of your adventures.
            That's why we strive to provide fast and efficient repairs so you
            can get back on the water as soon as possible. Quality Guaranteed:
            We pride ourselves on the quality of our services and offer a
            warranty on all work performed. Trust us to take care of your motor
            and we will ensure it runs reliably. Don't put repairs on hold -
            contact us today for a consultation and appointment for motor
            repair. Together we'll keep your motor in top shape
          </h5>
          <h5 class="subtext mb">
            <ul class="list">
              <li class="list-items">
                repair of equipment, boards and microchips
              </li>
              <li class="list-items">calibration of equipment</li>
              <li class="list-items">setup and programming</li>
              <li class="list-items">condition Auditing</li>
              <li class="list-items">
                replacement and modernization - Preventive Maintenance
              </li>
            </ul>
          </h5>
        </div>
        <div class="imgs-container">
          <img
            src="@/assets/engines/1.png"
            width="400px"
            class="compass"
          />
        </div>
      </div>
    </div>
    <div class="img-container container">
      <img :src="img.icon" v-for="img in imgs" :key="img" width="300" />
    </div>
  </div>
</template>

<script setup>
const imgs = [
  {
    icon: require("@/assets/engines/Screenshot 2024-02-05 at 22.13.41.png"),
    id: 1,
  },
  {
    icon: require("@/assets/engines/Screenshot 2024-02-05 at 22.15.56.png"),
    id: 1,
  },
  {
    icon: require("@/assets/engines/Screenshot 2024-02-05 at 22.16.19.png"),
    id: 1,
  },
  {
    icon: require("@/assets/engines/JPEG image-46AB-80AF-13-1.jpeg"),
    id: 1,
  },
  {
    icon: require("@/assets/engines/JPEG image-46AB-80AF-13-7.jpeg"),
    id: 1,
  },
  {
    icon: require("@/assets/engines/JPEG image-46AB-80AF-13-13.jpeg"),
    id: 1,
  },
];
</script>
<style lang="scss" scoped>
.imgs-container {
  img {
    display: block;
    margin: 0 auto;
  }
}
.img-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  img {
    display: block;
    //margin: 0 auto 20px auto;
    max-width: 420px;
    width: 48%;

    @media screen and (min-width: 1440px) {
      margin-bottom: 30px;
      width: 30%;
    }
  }
}
#micro {
  margin: 40px auto;
  .h2 {
    position: relative;
    padding-left: 12px;
    margin-bottom: 32px;
    span {
      display: block;
    }
    @media screen and (min-width: 1440px) {
      margin-bottom: 30px;
    }
  }
  ul {
    margin-top: 20px;
  }
  .h2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 72px;
    background-color: $main-blue;
    transition: all 1s;
  }
  .full {
    position: relative;
  }
  .list li {
    list-style-type: none;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    padding: 3px 0 3px 28px;
    font-weight: 500;
    font-family: "Outfit";
    background: url("../assets/icons/boat-anchor.svg");
    background-repeat: no-repeat;
  }
  ul.list li {
    background-position: left 4px;
  }
  .mb-desk {
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  .mb {
    margin-bottom: 20px;
  }
  .servicesForList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;

    li {
      width: 44%;
      margin-bottom: 40px;
      @media screen and (min-width: 768px) {
        width: 20%;
        margin-bottom: 0;
      }

      img {
        margin: 0 auto;
        @media screen and (min-width: 390px) {
          width: 60px;
        }
        @media screen and (min-width: 768px) {
          width: 50px;
          margin-bottom: 15px;
        }
      }

      .h4 {
        text-align: center;
      }
    }

    @media screen and (min-width: 768px) {
      flex-wrap: nowrap;
      width: 68%;
    }
    @media screen and (min-width: 1440px) {
      width: 100%;
    }
  }

  .servicesForList li:nth-last-of-type(-n + 2) {
    margin-bottom: 0;
  }

  .servicesForList li:first-child {
    @media screen and (min-width: 768px) {
      order: 1;
    }
  }

  .servicesForList li:nth-child(2) {
    @media screen and (min-width: 768px) {
      order: 2;
    }
  }

  .servicesForList li:nth-child(3) {
    @media screen and (min-width: 768px) {
      order: 4;
    }
  }

  .servicesForList li:nth-child(4) {
    @media screen and (min-width: 768px) {
      order: 3;
    }
  }
  .servicesFor.container {
    @media screen and (min-width: 1440px) {
      width: 100%;
      margin-top: 45px;
    }
  }
  .au-content {
    @media screen and (min-width: 1440px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 50px;
    }
  }
  .au-text {
    @media screen and (min-width: 1440px) {
      display: flex;
      flex-direction: column;
      width: 64%;
    }
    @media screen and (min-width: 1920px) {
      width: 75%;
    }
  }
  .eng-cont {
    max-width: 90%;
  }
}
</style>

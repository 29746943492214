import { createApp } from "vue";
import App from "./App.vue";
import VueSmoothScroll from "vue3-smooth-scroll";
import VueGtag from "vue-gtag";
import { createI18n } from "vue-i18n";
import router from "./router";
import "./assets/styles.scss";
import Vuelidate from "vuelidate";
import en from "@/locales/en.json";
import esp from "@/locales/esp.json";
import { vMaska } from "maska";
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: "en",
  messages: {
    en,
    esp,
  },
});

const app = createApp(App);
app
  .use(VueSmoothScroll, {
    duration: 2000,
    updateHistory: false,
    offset: -200,
  })
  .use(VueGtag, {
    config: { id: "G-9BWSJMWJDR" },
  });

app.use(i18n).use(router).use(Vuelidate).directive("maska", vMaska);
app.mount("#app");

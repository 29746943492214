<template>
  <div class="full">
    <div id="satellite" class="container">
      <div class="au-content">
        <div class="au-text">
          <h4 class="h2">
            Sound and video equipment for boats, safety systems on the water.
          </h4>
          <h5 class="subtext mb-desk">
            Sound and video equipment for boats, safety systems on the water.
            Discover a new level of comfort and entertainment on the water with
            our innovative services for installing sound and video systems on
            your boat! Our offerings: Marine Audio System: Enjoy high quality
            music anywhere on your offshore adventures. Live Video Surveillance:
            Stay aware of what is happening around your vessel, providing
            reliable control and security. Installation of fire alarm and smoke
            alarm systems: Keep your nautical travels and your boat safe with
            reliable and up-to-date equipment. Benefits of our services:
            Professional solutions: Our experts offer customized and integrated
            systems for your boat. Sound and picture quality: We use advanced
            technology for unrivaled sound and crystal clear video. Reliability:
            Your safety and satisfaction is our priority. Contact us today to
            discuss your needs and receive a customized quote.
          </h5>
          <div class="subtext mb">
            <ul class="list">
              <li class="list-items">Dynamic sound</li>
              <li class="list-items">
                Video of systems for mooring a vessel to the marina
              </li>
              <li class="list-items">Satellite TV</li>
              <li class="list-items">Wifi and internet access</li>
              <li class="list-items">
                Networking of all systems of the boat Hub
              </li>
            </ul>
          </div>
        </div>
        <div class="imgs-container">
          <img
            src="@/assets/satellite/main.jpeg"
            width="400px"
            class="compass"
          />
        </div>
      </div>
    </div>
    <div class="img-container container">
      <img :src="img.icon" v-for="img in imgs" :key="img" width="300" />
    </div>
  </div>
</template>
<script setup>
const imgs = [
  {
    icon: require("@/assets/satellite/JPEG image-48ED-BD2F-D9-0.jpeg"),
    id: 1,
  },
  {
    icon: require("@/assets/satellite/JPEG image-48ED-BD2F-D9-1.jpeg"),
    id: 6,
  },
  {
    icon: require("@/assets/satellite/JPEG image-48ED-BD2F-D9-2.jpeg"),
    id: 2,
  },
  {
    icon: require("@/assets/satellite/JPEG image-48ED-BD2F-D9-3.jpeg"),
    id: 3,
  },
  {
    icon: require("@/assets/satellite/JPEG image-48ED-BD2F-D9-4.jpeg"),
    id: 5,
  },
  {
    icon: require("@/assets/satellite/JPEG image-48ED-BD2F-D9-5.jpeg"),
    id: 5,
  },
];
</script>
<style lang="scss" scoped>
.imgs-container {
  img {
    display: block;
    margin: 0 auto;
  }
}
.img-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  img {
    display: block;
    margin: 0 auto 20px auto;
    max-width: 420px;
    width: 48%;

    @media screen and (min-width: 1440px) {
      margin-bottom: 30px;
      width: 30%;
    }
  }
}
#satellite {
  margin: 40px auto;
  .list li {
    list-style-type: none;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    padding: 3px 0 3px 28px;
    font-weight: 500;
    font-family: "Outfit";
    background: url("../assets/icons/boat-anchor.svg");
    background-repeat: no-repeat;
  }
  ul.list li {
    background-position: left 4px;
  }
  .h2 {
    position: relative;
    padding-left: 12px;
    margin-bottom: 32px;
    span {
      display: block;
    }
    @media screen and (min-width: 1440px) {
      margin-bottom: 30px;
    }
  }
  ul {
    margin-top: 20px;
  }
  .h2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 36px;
    background-color: $main-blue;
    transition: all 1s;
  }
  .full {
    position: relative;
  }
  .mb-desk {
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
      margin-bottom: 20px;
    }
  }

  .mb {
    margin-bottom: 20px;
  }
  .servicesForList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;

    li {
      width: 44%;
      margin-bottom: 40px;
      @media screen and (min-width: 768px) {
        width: 20%;
        margin-bottom: 0;
      }

      img {
        margin: 0 auto;
        @media screen and (min-width: 390px) {
          width: 60px;
        }
        @media screen and (min-width: 768px) {
          width: 50px;
          margin-bottom: 15px;
        }
      }

      .h4 {
        text-align: center;
      }
    }

    @media screen and (min-width: 768px) {
      flex-wrap: nowrap;
      width: 68%;
    }
    @media screen and (min-width: 1440px) {
      width: 100%;
    }
  }

  .servicesForList li:nth-last-of-type(-n + 2) {
    margin-bottom: 0;
  }

  .servicesForList li:first-child {
    @media screen and (min-width: 768px) {
      order: 1;
    }
  }

  .servicesForList li:nth-child(2) {
    @media screen and (min-width: 768px) {
      order: 2;
    }
  }

  .servicesForList li:nth-child(3) {
    @media screen and (min-width: 768px) {
      order: 4;
    }
  }

  .servicesForList li:nth-child(4) {
    @media screen and (min-width: 768px) {
      order: 3;
    }
  }
  .servicesFor.container {
    @media screen and (min-width: 1440px) {
      width: 100%;
      margin-top: 45px;
    }
  }
  .au-content {
    @media screen and (min-width: 1440px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .au-text {
    @media screen and (min-width: 1440px) {
      display: flex;
      flex-direction: column;
      width: 51%;
    }
    @media screen and (min-width: 1920px) {
      width: 75%;
    }
  }
}
</style>

<template>
  <div class="full">
    <div id="electrical" class="container">
      <div class="au-content">
        <div class="au-text">
          <h4 class="h2">
            Expert Boat Electrical Repair Services: Ensuring Vessel Safety and
            Reliability
          </h4>
          <h5 class="text mb-desk">
            At our company, we specialize in professional boat electrical repair
            services, leveraging the skills of our highly experienced craftsmen.
            We understand the importance of a fully functional and reliable
            electrical system in your marine vessel. Our team is equipped to
            perform comprehensive diagnostics and repairs, addressing issues
            from basic wiring faults to complex system overhauls. We prioritize
            safety and efficiency, ensuring that your boat's electrical system
            is restored to optimal condition. Trust us to maintain the integrity
            of your vessel with our expert services, keeping you safe and secure
            on the water.
          </h5>
          <div class="subtext mb">
            <ul class="list">
              <li class="list-items">batteries</li>
              <li class="list-items">charging systems</li>
              <li class="list-items">electrical troubleshooting</li>
              <li class="list-items">Audio/Acoustic Systems/Sound Systems</li>
              <li class="list-items">Satellite TV</li>
              <li class="list-items">Marine Wifi</li>
            </ul>
          </div>
        </div>
        <div class="imgs-container">
          <img
            src="@/assets/category/electrical.png"
            width="400px"
            class="compass"
          />
        </div>
      </div>
    </div>
    <div class="img-container container">
      <img :src="img.icon" v-for="img in imgs" :key="img" width="300" />
    </div>
  </div>
</template>
<script setup>
const imgs = [
  {
    icon: require("@/assets/electricity/photo_2023-08-24 00.06.10.jpeg"),
    id: 1,
  },
  {
    icon: require("@/assets/electricity/photo_2023-08-24 00.06.13.jpeg"),
    id: 2,
  },
  {
    icon: require("@/assets/electricity/photo_2023-08-24 00.06.32.jpeg"),
    id: 9,
  },
  {
    icon: require("@/assets/electricity/JPEG image-4BCC-BC16-BC-0.jpeg"),
    id: 9,
  },
  {
    icon: require("@/assets/electricity/JPEG image-4BCC-BC16-BC-2.jpeg"),
    id: 10,
  },
  {
    icon: require("@/assets/electricity/JPEG image-4BCC-BC16-BC-1.jpeg"),
    id: 11,
  },
];
</script>
<style lang="scss" scoped>
.imgs-container {
  img {
    display: block;
    margin: 0 auto;
  }
}
.img-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  img {
    display: block;
    margin: 0 auto 20px auto;
    max-width: 420px;
    width: 48%;

    @media screen and (min-width: 1440px) {
      margin-bottom: 30px;
      width: 30%;
    }
  }
}
#electrical {
  margin: 40px auto;
  .list li {
    list-style-type: none;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    padding: 3px 0 3px 28px;
    font-weight: 500;
    font-size: 17px;
    font-family: "Outfit";
    background: url("../assets/icons/boat-anchor.svg");
    background-repeat: no-repeat;
  }
  ul.list li {
    background-position: left 4px;
  }
  .h2 {
    position: relative;
    padding-left: 12px;
    margin-bottom: 32px;
    span {
      display: block;
    }
    @media screen and (min-width: 1440px) {
      margin-bottom: 30px;
    }
  }
  ul {
    margin-top: 20px;
  }
  .h2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 72px;
    background-color: $main-blue;
    transition: all 1s;
  }
  .full {
    position: relative;
  }
  .mb-desk {
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
      margin-bottom: 20px;
    }
  }

  .mb {
    margin-bottom: 20px;
  }
  .servicesForList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;

    li {
      width: 44%;
      margin-bottom: 40px;
      @media screen and (min-width: 768px) {
        width: 20%;
        margin-bottom: 0;
      }

      img {
        margin: 0 auto;
        @media screen and (min-width: 390px) {
          width: 60px;
        }
        @media screen and (min-width: 768px) {
          width: 50px;
          margin-bottom: 15px;
        }
      }

      .h4 {
        text-align: center;
      }
    }

    @media screen and (min-width: 768px) {
      flex-wrap: nowrap;
      width: 68%;
    }
    @media screen and (min-width: 1440px) {
      width: 100%;
    }
  }

  .servicesForList li:nth-last-of-type(-n + 2) {
    margin-bottom: 0;
  }

  .servicesForList li:first-child {
    @media screen and (min-width: 768px) {
      order: 1;
    }
  }

  .servicesForList li:nth-child(2) {
    @media screen and (min-width: 768px) {
      order: 2;
    }
  }

  .servicesForList li:nth-child(3) {
    @media screen and (min-width: 768px) {
      order: 4;
    }
  }

  .servicesForList li:nth-child(4) {
    @media screen and (min-width: 768px) {
      order: 3;
    }
  }
  .servicesFor.container {
    @media screen and (min-width: 1440px) {
      width: 100%;
      margin-top: 45px;
    }
  }
  .au-content {
    @media screen and (min-width: 1440px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .au-text {
    @media screen and (min-width: 1440px) {
      display: flex;
      flex-direction: column;
      width: 51%;
    }
    @media screen and (min-width: 1920px) {
      width: 75%;
    }
  }
}
</style>

<template>
  <div id="feedbacks" class="container">
    <h2 class="h2">{{ $t("satisfied") }}</h2>
    <vueper-slides
      fade
      :touchable="false"
      :bullets="false"
      class="mobile tablet"
      autoplay
    >
      <vueper-slide v-for="(slide, i) in slides" :key="i">
        <template #content>
          <div class="content">
            <img :src="slide.img" alt="logo" />
            <div class="feedback">
              <img src="@/assets/icons/quote.svg" alt="quote" />
              <h4 class="subtext">
                {{ $t(slide.content) }}
              </h4>
              <p class="name">{{ slide.author }}</p>
              <div class="star-cont">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="color: gold"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"
                    fill="gold"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="color: gold"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"
                    fill="gold"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="color: gold"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"
                    fill="gold"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="color: gold"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"
                    fill="gold"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="color: gold"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"
                    fill="gold"
                  />
                </svg>
              </div>
            </div>
          </div>
        </template>
      </vueper-slide>
    </vueper-slides>
    <vueper-slides
      class="no-shadow desktop container"
      :visible-slides="2"
      :bullets="false"
      slide-multiple
      :slide-ratio="1 / 4"
      :gap="5"
      :arrows-outside="false"
    >
      <vueper-slide v-for="(slide, i) in slides" :key="i">
        <template #content>
          <div class="container content">
            <div class="feedback-content">
              <div class="feedback-img">
                <img :src="slide.img" alt="logo" />
              </div>
              <div class="feedback-text">
                <p class="name">{{ slide.author }}</p>
                <h4 class="subtext">
                  {{ $t(slide.content) }}
                </h4>
                <div class="star-cont">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style="color: gold"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"
                      fill="gold"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style="color: gold"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"
                      fill="gold"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style="color: gold"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"
                      fill="gold"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style="color: gold"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"
                      fill="gold"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style="color: gold"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"
                      fill="gold"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </template>
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script setup>
import "vueperslides/dist/vueperslides.css";
import { VueperSlides, VueperSlide } from "vueperslides";
import StarRating from "vue-star-rating";

const slides = [
  {
    content: "fb1",
    author: "Victor - Beneteau Antares 11 Fly ",
    img: require("@/assets/victor.png"),
  },
  {
    content: "fb2",
    author: "Alex Y. - Symbol 66 ft Pilothouse 2000",
    img: require("@/assets/alex.png"),
  },
  {
    content: "fb3",
    author: "Gustavo Ceballos - SeaRay",
    img: require("@/assets/gustavo.png"),
  },
  {
    content: "fb4",
    author: "Steven Raine - Robalo 360 r three outboard motors",
    img: require("@/assets/steven.png"),
  },
];
</script>

<style lang="scss">
#feedbacks {
  width: 100% !important;
  margin-top: 120px;
  @media screen and (min-width: 1440px) {
    //margin-top: 10px;
  }
  .vueperslides__track-inner .vueperslide {
    @media screen and (min-width: 1440px) {
      width: 49% !important;
      margin-right: 39px !important;
    }
  }
  .vueperslides__track-inner .vueperslide:nth-child(odd) {
    background-color: $main-blue;
  }
  .vueperslides {
    @media screen and (min-width: 768px) {
      max-width: 690px;
      margin: 0 auto;
    }
    @media screen and (min-width: 1440px) {
      max-width: 100%;
    }
  }

  .vueperslides__track-inner .vueperslide:nth-child(even) {
    background-color: #15263c;
  }

  .vueperslides__parallax-wrapper {
    height: 170px;
    @media screen and (min-width: 390px) {
      height: 220px;
    }
    @media screen and (min-width: 768px) {
      height: 400px;
      max-height: 400px;
      padding: 0 !important;
    }
  }
  .vueperslide {
    display: flex;
    align-items: center;
  }
  .vueperslides__arrow--prev {
    display: none;
  }
  .vueperslides__track-inner .vueperslide:nth-child(3) .feedback-content {
    margin-left: 18px;
  }
}
</style>

<style scoped lang="scss">
.h2 {
  text-align: center;
  margin-bottom: 60px;
}
.feedback {
  display: flex;
  justify-content: space-between;
}
.star-cont {
  display: flex;
  svg {
    margin-right: 8px;
  }
}
.feedback-img img {
  width: 153px;
  max-width: fit-content;
  margin-right: 20px;
}
.content {
  display: flex;
  align-items: center;
  height: 100%;

  .photo {
    margin-right: 10px;
    margin-left: 20px;
    @media screen and (min-width: 768px) {
      height: 180px;
      margin-right: 30px;
      margin-left: 0;
    }
  }
  .subtext {
    color: white;
    margin-top: 5px;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0em;
    @media screen and (min-width: 1440px) {
      font-size: 15px;
      font-weight: 300;
      line-height: 22px;
      letter-spacing: 0em;
      margin-top: 3px;
      margin-bottom: 10px;
    }
  }
  .feedback-content {
    width: 91%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 25px 0 25px 30px;
    @media screen and (min-width: 1440px) {
    }
    .name {
      font-family: Outfit;
      font-size: 15px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: white;
      text-decoration: underline;
      margin-bottom: 15px;
      @media screen and (min-width: 1440px) {
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0em;
      }
    }
  }
}
</style>

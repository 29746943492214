<template>
  <div class="full">
    <div id="radio" class="container">
      <div class="au-content">
        <div class="au-text">
          <h4 class="h2">Installation of customized lighting for your boat</h4>
          <h5 class="subtext mb-desk light-cont">
            Lighting for Brightness and Safety: Our advanced LED
            systems provide maximum brightness, making your boat visible in all
            conditions and creating a magical atmosphere. Easy Installation: Our
            installation experts are ready to make the installation process as
            convenient as possible for you. No matter the size of your boat, we
            will create customizable lighting to fit your needs. Energy
            Efficiency: We offer state-of-the-art lighting technology that is
            not only beautiful, but also energy efficient. Travel without the
            hassle of worrying about battery power! Don't miss the chance to
            make your boat unique! Contact us today for a consultation and to
            book your lighting installation. Let the light accompany you on your
            nautical adventures!
          </h5>
          <h5 class="subtext mb">
            <ul class="list">
              <li class="list-items">Installation of customized lighting for your boat</li>
              <li class="list-items">Illuminated running lights for your boating safety</li>
              <li class="list-items">Boat exterior and interior lighting</li>
              <li class="list-items">LED lighting</li>
              <li class="list-items">Cabin, ladder, galley, deck lighting</li>
              <li class="list-items">Unique and customized solutions</li>
            </ul>
          </h5>
        </div>
        <div class="imgs-container">
          <img
            src="../../src/assets/radio/main.jpeg"
            width="400px"
            class="compass"
          />
        </div>
      </div>
    </div>
    <div class="img-container container">
      <img :src="img.icon" v-for="img in imgs" :key="img" width="300" />
    </div>
  </div>
</template>
<script setup>
const imgs = [
  {
    icon: require("@/assets/radio/JPEG image-4878-88B6-F8-0.jpeg"),
    id: 1,
  },
  {
    icon: require("@/assets/radio/JPEG image-4273-BBB1-C5-0.jpeg"),
    id: 1,
  },
  {
    icon: require("@/assets/radio/JPEG image-4878-88B6-F8-2.jpeg"),
    id: 1,
  },
  {
    icon: require("@/assets/radio/JPEG image-4878-88B6-F8-3.jpeg"),
    id: 1,
  },
  {
    icon: require("@/assets/radio/JPEG image-4878-88B6-F8-4.jpeg"),
    id: 1,
  },
  {
    icon: require("@/assets/radio/JPEG image-4878-88B6-F8-5.jpeg"),
    id: 1,
  },
];
</script>
<style lang="scss" scoped>
.imgs-container {
  img {
    display: block;
    margin: 0 auto;
  }
}
.img-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  img {
    display: block;
    margin: 0 auto 20px auto;
    max-width: 420px;
    width: 48%;

    @media screen and (min-width: 1440px) {
      margin-bottom: 30px;
      width: 30%;
    }
  }
}
#radio {
  margin: 40px auto;
  .list li {
    list-style-type: none;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    padding: 3px 0 3px 28px;
    font-weight: 500;
    font-family: "Outfit";
    background: url("../assets/icons/boat-anchor.svg");
    background-repeat: no-repeat;
  }
  ul.list li {
    background-position: left 4px;
  }
  .h2 {
    position: relative;
    padding-left: 12px;
    margin-bottom: 32px;
    span {
      display: block;
    }
    @media screen and (min-width: 1440px) {
      margin-bottom: 30px;
    }
  }
  ul {
    margin-top: 20px;
  }
  .h2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 36px;
    background-color: $main-blue;
    transition: all 1s;
  }
  .full {
    position: relative;
  }
  .mb-desk {
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
      margin-bottom: 20px;
    }
  }

  .mb {
    margin-bottom: 20px;
  }
  .servicesForList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;

    li {
      width: 44%;
      margin-bottom: 40px;
      @media screen and (min-width: 768px) {
        width: 20%;
        margin-bottom: 0;
      }

      img {
        margin: 0 auto;
        @media screen and (min-width: 390px) {
          width: 60px;
        }
        @media screen and (min-width: 768px) {
          width: 50px;
          margin-bottom: 15px;
        }
      }

      .h4 {
        text-align: center;
      }
    }

    @media screen and (min-width: 768px) {
      flex-wrap: nowrap;
      width: 68%;
    }
    @media screen and (min-width: 1440px) {
      width: 100%;
    }
  }

  .servicesForList li:nth-last-of-type(-n + 2) {
    margin-bottom: 0;
  }

  .servicesForList li:first-child {
    @media screen and (min-width: 768px) {
      order: 1;
    }
  }

  .servicesForList li:nth-child(2) {
    @media screen and (min-width: 768px) {
      order: 2;
    }
  }

  .servicesForList li:nth-child(3) {
    @media screen and (min-width: 768px) {
      order: 4;
    }
  }

  .servicesForList li:nth-child(4) {
    @media screen and (min-width: 768px) {
      order: 3;
    }
  }
  .servicesFor.container {
    @media screen and (min-width: 1440px) {
      width: 100%;
      margin-top: 45px;
    }
  }
  .au-content {
    @media screen and (min-width: 1440px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .au-text {
    @media screen and (min-width: 1440px) {
      display: flex;
      flex-direction: column;
      width: 51%;
    }
    @media screen and (min-width: 1920px) {
      width: 75%;
    }
  }
}
</style>

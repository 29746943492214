<template>
  <div class="banner">
    <div id="main-slider">
      <vueper-slides fade :touchable="false" autoplay>
        <vueper-slide
          v-for="(slide, i) in slides"
          :key="i"
          :image="slide.image"
        >
          <template #content>
            <div class="container">
              <div class="title-container">
                <h1 class="h1 slider-title">
                  {{ $t(slide.content) }}
                </h1>
              </div>
              <a href="#contact" class="btn" v-smooth-scroll>{{
                $t("contactUs")
              }}</a>
            </div>
          </template>
        </vueper-slide>
      </vueper-slides>
      <ul class="info-list">
        <li class="info-item" v-for="item in menu">
          <h4 class="h2">Fast and Reliable European</h4>
          <span class="h2 eng">Engineering Solutions</span>
          <p class="tagline text">{{ $t(item.subtitle) }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import "vueperslides/dist/vueperslides.css";
import { VueperSlides, VueperSlide } from "vueperslides";
import Header from "@/App";

const slides = [
  // {
  //   image: require("@/assets/slider/rect.png"),
  //   content:
  //     "Service repair, supply and annual inspection of navigation, satellite and radio equipment.",
  //   subContent:
  //     "Our company works with vessels of any type, yacht equipment,liners, fishing, transport and tanker fleet.",
  // },
  {
    image: require("@/assets/slider/boat1.png"),
    content:
      "Maintenance and support of the good condition of electrical equipment. Repair and restoration.",
    subContent:
      "Our company works with vessels of any type, yacht equipment,liners, fishing, transport and tanker fleet.",
  },
  // {
  //   image: require("@/assets/slider/k.png"),
  //   content:
  //     "Expert service for boats and yachts. Installation of radio, navigation and satellite equipment, replacement and installation electrical equipment.",
  //   subContent:
  //     "Our company works with vessels of any type, yacht equipment,liners, fishing, transport and tanker fleet.",
  // },
];
const menu = [
  {
    title: "30+",
    subtitle: "years of experience",
  },
];
</script>

<style lang="scss">
#main-slider {
  .eng {
    margin-bottom: 15px!important;
    display: block;
  }
  .tagline {
    text-transform: uppercase;
    color: #246367;
  }
  @media screen and (min-width: 1440px) {
    //max-width: 1110px;
    margin: 0 auto;
    position: relative;
  }
  .vueperslides__parallax-wrapper {
    min-height: calc(100vh - 103.27px);
    //@media screen and (min-width: 390px) {
    //  min-height: 100vh;
    //}
    //@media screen and (min-width: 768px) {
    //  min-height: 100vh;
    //}
    //@media screen and (min-width: 1440px) {
    //  min-height: 664px;
    //}
  }

  .vueperslides__bullets .vueperslides__bullet .default {
    width: 20px;
    height: 2px;
    border-radius: 1px;
    background: rgba(255, 255, 255, 0.5);
  }

  .vueperslides__bullets
    .vueperslides__bullet.vueperslides__bullet--active
    .default {
    width: 36px;
    height: 2px;
    background-color: $accent-orange;
    border: none;
  }

  .vueperslides__bullet {
    margin: 40px 0.6em;
  }

  .slider-title,
  .slider-subtitle {
    color: $white;
    margin-bottom: 0px;
  }
  .slider-subtitle {
    @media screen and (min-width: 1440px) {
      max-width: 262px;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .vueperslides__arrow {
    display: block;
    @media screen and (min-width: 1440px) {
      display: block;
    }
  }

  .vueperslide {
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .container {
      @media screen and (min-width: 768px) {
        width: 42%;
        margin: 16px auto 0 39px;
      }
      @media screen and (min-width: 1440px) {
        margin: 0px auto 0 95px;
        width: 45%;
      }
    }
  }
  .vueperslide:nth-child(1) {
    @media screen and (min-width: 768px) {
      background-image: url("../assets/slider/boat1Tab.png") !important;
    }
  }
  //.vueperslide:nth-child(3) {
  //  @media screen and (min-width: 768px) {
  //    background-image: url("../assets/slider/compassTab.png") !important;
  //  }
  //}
  //.vueperslide:nth-child(1) {
  //  @media screen and (min-width: 768px) {
  //    background-image: url("../assets/slider/capTab.png") !important;
  //  }
  //}
  .info-list {
    padding: 40px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    .info-item {
      //width: 30%;
      .h2 {
        margin-bottom: 5px;
        font-size: 18px;
        line-height: 24px;
      }
      .subtext {
        font-size: 14px;
      }
    }
    @media screen and (min-width: 768px) {
      width: 41%;
    }
    @media screen and (min-width: 1440px) {
      position: absolute;
      bottom: -1px;
      right: -1px;
      z-index: 9999;
      width: 476px;
      background-color: white;
      padding: 30px 40px;
    }
  }
  .info-list::before {
    display: block;
    content: "";
    height: 1px;
    width: 415px;
    position: absolute;
    background-color: white;
    top: -20px;
  }
  .title-container {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 30px;
    .h1 {
      font-size: 30px;
    }
  }

  .title-container::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(52, 24, 2, 0.25);
    filter: blur(10px);
    z-index: -1; /* This positions the pseudo-element behind the content */
  }
}
</style>

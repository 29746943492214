import { createRouter, createWebHistory } from "vue-router";
import NotFoundPage from "../views/NotFound";
import Home from "../views/Home";
import Engines from "../views/Microchips";
import Electrical from "../views/Electrical";
import Navigation from "../views/Navigation";
import Radio from "../views/Radio";
import Satellite from "../views/Satellite";
import Support from "../views/Support";
const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/engines",
    component: Engines,
  },
  {
    path: "/electrical",
    component: Electrical,
  },
  {
    path: "/navigation",
    component: Navigation,
  },
  {
    path: "/lighting",
    component: Radio,
  },
  {
    path: "/video",
    component: Satellite,
  },
  {
    path: "/pumps",
    component: Support,
  },
  { path: "/:pathMatch(.*)*", component: NotFoundPage },
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
  routes,
});

export default router;

<template>
  <Header />
  <router-view />
  <ContactUs />
  <Footer />
</template>

<script setup>
import { watch, ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
import { useI18n } from "vue-i18n";
import Header from "@/components/Header/Header";
import ContactUs from "@/components/ContactUs/ContactUs";
import Footer from "@/components/Footer";
const { t } = useI18n({ useScope: "global" });

watch(
  useI18n({ useScope: "global" }).locale,
  (value) => (document.documentElement.lang = value && router.push(`/${value}`))
);
</script>

<template>
  <div class="full">
    <div id="support" class="container">
      <div class="au-content">
        <div class="au-text">
          <h4 class="h2">Water pumps and water systems</h4>
          <h5 class="subtext mb-desk">
            Ensure the reliability and efficiency of your water equipment with
            our professional water pump and pump repair services. Our
            experienced technicians are ready to restore the functionality of
            your equipment.
            Water Pumps & Pumps Repair: We specialize in
            repairing different types of water pumps and pumps.
            Professional Diagnosis: Our experts will perform a
            thorough diagnosis to identify any faults in your water equipment.
            This will help us accurately determine the scope of work and offer
            the best solutions.
            We understand the importance of reliable water systems.
            After our repairs, you'll be able to enjoy a steady
            supply of water with the confidence of knowing that your equipment
            is in top condition.
            Service & Maintenance: In addition to
            repairs, we offer regular water system maintenance to prevent
            potential problems. This will help you maintain the efficiency and
            longevity of your equipment. Don't put off water pump and water pump
            repair until later. Contact us today to have our professionals help
            you restore and maintain your water equipment in top condition.
          </h5>
          <h5 class="subtext mb">
            <ul class="list">
              <li class="list-items">Installation and repair of the water supply system</li>
              <li class="list-items">Replacement of taps</li>
              <li class="list-items">Water tanks</li>
              <li class="list-items">Boat watertightness</li>
            </ul>
          </h5>
        </div>
        <div class="imgs-container">
          <img
            src="@/assets/category/support.png"
            width="400px"
            class="compass"
          />
        </div>
      </div>
    </div>
    <div class="img-container container">
      <img :src="img.icon" v-for="img in imgs" :key="img" width="300" />
    </div>
  </div>
</template>
<script setup>
const imgs = [
  {
    icon: require("@/assets/life/JPEG image-47E0-BF20-6D-0.jpeg"),
    id: 1,
  },
  {
    icon: require("@/assets/life/JPEG image-47E0-BF20-6D-1.jpeg"),
    id: 6,
  },
  {
    icon: require("@/assets/life/JPEG image-47E0-BF20-6D-2.jpeg"),
    id: 2,
  },
  {
    icon: require("@/assets/life/JPEG image-47E0-BF20-6D-3.jpeg"),
    id: 3,
  },
  {
    icon: require("@/assets/life/JPEG image-47E0-BF20-6D-4.jpeg"),
    id: 5,
  },
  {
    icon: require("@/assets/life/JPEG image-47E0-BF20-6D-5.jpeg"),
    id: 5,
  },
];
</script>

<style lang="scss" scoped>
.imgs-container {
  img {
    display: block;
    margin: 0 auto;
  }
}
.img-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  img {
    display: block;
    margin: 0 auto 20px auto;
    max-width: 420px;
    width: 48%;

    @media screen and (min-width: 1440px) {
      margin-bottom: 30px;
      width: 30%;
    }
  }
}
#support {
  margin: 40px auto;
  .list li {
    list-style-type: none;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    padding: 3px 0 3px 28px;
    font-weight: 500;
    font-family: "Outfit";
    background: url("../assets/icons/boat-anchor.svg");
    background-repeat: no-repeat;
  }
  ul.list li {
    background-position: left 4px;
  }
  .h2 {
    position: relative;
    padding-left: 12px;
    margin-bottom: 32px;
    span {
      display: block;
    }
    @media screen and (min-width: 1440px) {
      margin-bottom: 30px;
    }
  }
  ul {
    margin-top: 20px;
  }
  .h2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 36px;
    background-color: $main-blue;
    transition: all 1s;
  }
  .full {
    position: relative;
  }
  .mb-desk {
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
      margin-bottom: 20px;
    }
  }

  .mb {
    margin-bottom: 20px;
  }
  .servicesForList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;

    li {
      width: 44%;
      margin-bottom: 40px;
      @media screen and (min-width: 768px) {
        width: 20%;
        margin-bottom: 0;
      }

      img {
        margin: 0 auto;
        @media screen and (min-width: 390px) {
          width: 60px;
        }
        @media screen and (min-width: 768px) {
          width: 50px;
          margin-bottom: 15px;
        }
      }

      .h4 {
        text-align: center;
      }
    }

    @media screen and (min-width: 768px) {
      flex-wrap: nowrap;
      width: 68%;
    }
    @media screen and (min-width: 1440px) {
      width: 100%;
    }
  }

  .servicesForList li:nth-last-of-type(-n + 2) {
    margin-bottom: 0;
  }

  .servicesForList li:first-child {
    @media screen and (min-width: 768px) {
      order: 1;
    }
  }

  .servicesForList li:nth-child(2) {
    @media screen and (min-width: 768px) {
      order: 2;
    }
  }

  .servicesForList li:nth-child(3) {
    @media screen and (min-width: 768px) {
      order: 4;
    }
  }

  .servicesForList li:nth-child(4) {
    @media screen and (min-width: 768px) {
      order: 3;
    }
  }
  .servicesFor.container {
    @media screen and (min-width: 1440px) {
      width: 100%;
      margin-top: 45px;
    }
  }
  .au-content {
    @media screen and (min-width: 1440px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .au-text {
    @media screen and (min-width: 1440px) {
      display: flex;
      flex-direction: column;
      width: 51%;
    }
    @media screen and (min-width: 1920px) {
      width: 75%;
    }
  }
}
</style>
<script>
import ContactUs from "@/components/ContactUs/ContactUs";
export default {
  components: { ContactUs },
};
</script>

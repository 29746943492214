<template>
  <div>
    <div id="footer">
      <img src="@/assets/logo.png" class="logo mobile tablet" />
      <img
        src="@/assets/logo-light.svg"
        alt="logo"
        class="logo desktop"
        width="182"
      />
      <ul class="contacts-list container">
        <li class="contacts-item" v-for="icon in socialSites">
          <a
            :href="icon.link"
            :target="
              icon.id === 4 || icon.id === 1 || icon.id === 2 ? '_blank' : ''
            "
          >
            <img :src="icon.icon" :alt="icon.link" />
          </a>
        </li>
      </ul>
      <!--      <div class="footer-nav">-->
      <!--        <ul class="footerNav-list tablet">-->
      <!--          <li-->
      <!--            v-for="navItem in navigationList"-->
      <!--            :key="navItem.name"-->
      <!--            class="navLink"-->
      <!--          >-->
      <!--            <a :href="navItem.href" class="subtext" v-smooth-scroll>{{-->
      <!--              $t(navItem.name)-->
      <!--            }}</a>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </div>-->
      <p class="subtext">All rights reserved</p>
    </div>
  </div>
</template>

<script setup>
const socialSites = [
  {
    icon: require("@/assets/icons/fb-soc.svg"),
    link: "https://www.facebook.com/profile.php?id=100094707335692",
    id: 1,
  },
  {
    icon: require("@/assets/icons/insta-soc.svg"),
    link: "https://www.instagram.com/invites/contact/?i=1g7y02idwiojy&utm_content=rgo22l9",
    id: 2,
  },
  {
    icon: require("@/assets/icons/phone-soc.svg"),
    link: "tel:+19549559109",
    id: 3,
  },
  {
    icon: require("@/assets/icons/mail-soc.svg"),
    link: "mailto:kstarmarineus@gmail.com",
    id: 4,
  },
];
const navigationList = [
  { name: "services", href: "#services" },
  { name: "feedbacks", href: "#feedbacks" },
  { name: "ourBlog", href: "#blog" },
  {
    name: "contactUs",
    href: "#contact",
  },
  {
    name: "+ 1(305)632-3687",
    href: "#contact",
  },
];
</script>

<style lang="scss">
.partners {
  display: flex;
  justify-content: center;
  background-color: $main-blue;
  padding: 15px;
  color: white;
  a:first-child {
    margin-right: 40px;
  }
  a:nth-child(2) {
    margin-right: 40px;
  }
  a {
    display: flex;
    align-items: center;
    min-width: 100px;
    @media screen and (min-width: 1440px) {
      width: 160px;
    }
  }
  .container {
    display: flex;
    overflow-x: scroll;
    justify-content: space-between;
    @media screen and (min-width: 690px) {
      overflow-x: auto;
    }
  }
}
.container::-webkit-scrollbar {
  background-color: transparent;
  height: 4px;
}
.mr-40 {
  margin-right: 40px;
}
#footer {
  padding: 15px 0;
  border-top: 1px solid $main-blue;

  .logo {
    margin: 0 auto 20px auto;
    @media screen and (min-width: 1440px) {
      margin: 0 auto 30px auto;
    }
  }
  .subtext {
    text-align: center;
    color: #acacac;
  }
  .contacts-list {
    display: flex;
    margin: 0 auto 30px auto;
    align-items: center;
    justify-content: center;
    li {
      margin-right: 30px;
    }
  }
  .contacts-list li:last-child {
    margin-right: 0;
  }
  .navLink {
    color: $grey;
  }
  .footerNav-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .footer-nav {
    max-width: 400px;
    margin: 0 auto 20px auto;
  }
  .contacts-item:hover img {
    filter: invert(50%) sepia(11%) saturate(2125%) hue-rotate(136deg)
      brightness(93%) contrast(86%);
  }
}
</style>

<template>
  <div id="contact">
    <div class="info">
      <div class="info-container">
        <h2 class="h2">{{ $t("contactUs") }}</h2>
        <p class="text">
          {{ $t("willCall") }}
        </p>
        <div class="contacts desktop">
          <div class="subContent container">
            <ul>
              <li v-for="item in contactData" class="subContent-item">
                <img :src="item.icon" />
                <a
                  :href="item.href"
                  :class="item.id === 1 ? 'phone item-link' : 'item-link'"
                  >{{ item.text }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <form action="https://submit-form.com/kxC6LYH7">
      <InputBase
        label="Name"
        :value="name"
        type="text"
        @inputValue="nameDetect"
        rules="required"
      />
      <InputBase
        label="Phone number"
        type="number"
        length="18"
        :value="phoneNumber"
        @inputValue="phoneNumberDetect"
        rules="required|length"
      />
      <InputBase
        label="Message"
        type="text"
        :value="message"
        @inputValue="messageDetect"
        rules="required"
      />
      <input
        class="btn"
        type="submit"
        :class="{
          disabled:
            message.length === 0 ||
            phoneNumber.length === 0 ||
            name.length === 0,
        }"
        :value="$t('Send')"
        @click="sendData"
        :disabled="
          message.length === 0 || phoneNumber.length === 0 || name.length === 0
        "
      />
    </form>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const name = ref("");
const lastName = ref("");
const companyName = ref("");
const email = ref("");
const phoneNumber = ref("");
const message = ref("");
import { event } from "vue-gtag";
const contactData = [
  {
    icon: require("@/assets/icons/phone.svg"),
    text: "+1 954-955-9109",
    href: "tel:+19549559109",
    id: 1,
  },
  {
    icon: require("@/assets/icons/mail.svg"),
    text: "kstarmarineus@gmail.com",
    href: "mailto:kstarmarineus@gmail.com",
    id: 2,
  },
];
const nameDetect = function (value) {
  name.value = value;
};
const phoneNumberDetect = function (value) {
  phoneNumber.value = value;
};
const messageDetect = function (value) {
  message.value = value;
};
const lastNameDetect = function (value) {
  lastName.value = value;
};
const companyNameDetect = function (value) {
  companyName.value = value;
};
const emailDetect = function (value) {
  email.value = value;
};
const currentLang = ref(route.path.slice(1, 3));
const sendData = function () {
  event("formSent", { method: "Google" });
};
</script>

<style scoped lang="scss">
.h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0em;
  @media screen and (min-width: 1440px) {
    text-align: left;
  }
}
.text {
  color: #246367;
  margin-bottom: 29px;
  font-size: 19px;
  text-align: center;
  @media screen and (min-width: 768px) {
    margin-bottom: 91px;
  }
  @media screen and (min-width: 1440px) {
    margin-bottom: 0;
    text-align: left;
    margin-bottom: 50px;
  }
}
.subContent-item {
  @media screen and (min-width: 1440px) {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    img {
      margin-right: 10px;
    }
  }
}
ul .subContent-item:last-child {
  margin-bottom: 0;
}
.btn {
  margin-top: 30px;
  @media screen and (min-width: 768px) {
    display: block;
    margin: 30px auto 0 auto;
  }
}
#contact {
  margin: 120px auto;
  max-width: 320px;
  @media screen and (min-width: 768px) {
    width: 450px;
    max-width: 100%;
    margin: 120px auto;
  }
  @media screen and (min-width: 1440px) {
    width: 1110px;
    max-width: 1110px;
    margin: 160px auto;
  }

  .item-link {
    @media screen and (min-width: 1440px) {
      font-size: 15px;
      font-weight: 300;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: black;
    }
  }
  @media screen and (min-width: 1440px) {
    margin-top: 160px;
    display: flex;
    justify-content: space-between;
  }
  .info {
    @media screen and (min-width: 1440px) {
      width: 43%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .info-container {
      @media screen and (min-width: 1440px) {
        max-width: 100%;
      }
    }
  }
  form {
    @media screen and (min-width: 1440px) {
      width: 50%;
    }
    .btn {
      margin: 30px 0 0 0;
      @media screen and (min-width: 768px) {
        margin: 30px auto 0 auto;
      }
      @media screen and (min-width: 1440px) {
        margin: 30px auto 0 0;
      }
    }
  }
}
.disabled {
  opacity: 0.5;
}
</style>
<script>
import InputBase from "@/components/base/InputBase";
export default {
  components: { InputBase },
};
</script>

<template>
  <div class="full">
    <div id="about" class="container">
      <h4 class="h2">Certificates</h4>
      <!--      <div class="bg-container">-->
      <!--        <div>-->
      <!--          <div class="au-content">-->
      <!--            <div class="au-text">-->
      <!--              <h5 class="subtext mb-desk">-->
      <!--                {{ $t("aboutText") }}-->
      <!--              </h5>-->
      <!--              <h5 class="subtext mb-desk">-->
      <!--                {{ $t("cof") }}-->
      <!--              </h5>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="photo-content">-->
      <!--        <img src="@/assets/personal.png" alt="photo" width="320" />-->
      <!--        <div class="name-content">-->
      <!--          <p class="text">Kirill Fominov</p>-->
      <!--          <p class="subtext">Co-Founder</p>-->
      <!--        </div>-->
      <!--      </div>-->

      <vueper-slides
        class="no-shadow desktop container"
        :visible-slides="3"
        :bullets="false"
        slide-multiple
        :slide-ratio="1 / 4"
        :gap="5"
        :arrows-outside="false"
        autoplay
      >
        <vueper-slide v-for="(slide, i) in certifictes" :key="i">
          <template #content>
            <div class="container content">
              <div class="feedback-content">
                <div class="feedback-img">
                  <img :src="slide.icon" alt="logo" width="370" />
                </div>
              </div>
            </div>
          </template>
        </vueper-slide>
      </vueper-slides>
    </div>
  </div>
</template>
<script setup>
import "vueperslides/dist/vueperslides.css";
import { VueperSlides, VueperSlide } from "vueperslides";
const certifictes = [
  {
    icon: require("@/assets/cerificates/4.png"),
    id: 4,
  },
  {
    icon: require("@/assets/cerificates/5.png"),
    id: 5,
  },

  {
    icon: require("@/assets/cerificates/7.png"),
    id: 7,
  },
  {
    icon: require("@/assets/cerificates/1.png"),
    id: 1,
  },
  {
    icon: require("@/assets/cerificates/2.png"),
    id: 2,
  },
  {
    icon: require("@/assets/cerificates/3.png"),
    id: 3,
  },

  {
    icon: require("@/assets/cerificates/8.png"),
    id: 8,
  },
  {
    icon: require("@/assets/cerificates/9.png"),
    id: 8,
  },
  {
    icon: require("@/assets/cerificates/10.png"),
    id: 8,
  },
];
</script>
<style lang="scss" scoped>
#about {
  margin: 120px auto;
  .vueperslide.vueperslide--visible {
    width: 33.3% !important;
  }

  .vueperslides__arrow {
    background-color: red !important;
  }

  @media screen and (min-width: 1440px) {
    //margin: 80px auto;
    //display: flex;
    //align-items: center;
    //flex-wrap: wrap;
    //position: relative;
    //width: 1110px;
  }
  .name-content {
    padding: 10px 0 0 0;
    @media screen and (min-width: 1440px) {
      text-align: left;
      width: 100%;
    }
    .subtext {
      text-indent: 0;
      margin: 5px 0 0 0;
    }
  }
  .certificates {
    //max-width: 690px;
    @media screen and (min-width: 1440px) {
      //display: none;
    }
  }
  .bg-container {
    background-color: #246367;
    padding: 60px 0;
    color: white;
    margin-bottom: 20px;
    @media screen and (min-width: 1440px) {
      margin: 114px 0 0 auto;
      max-width: 920px;
      width: 83%;
      padding: 60px 155px;
      order: 2;
    }
  }
  .photo-content {
    margin: 0 auto;
    position: relative;
    top: -70px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 320px;
    @media screen and (min-width: 1440px) {
      position: absolute;
      width: 255px;
      top: 21%;
      img {
        height: 404px;
      }
    }
  }
  ul {
    display: flex;
    overflow-x: scroll;
    justify-content: space-between;

    li {
      margin-right: 20px;
      min-width: 260px;
    }
  }
  .subtext {
    text-indent: 20px;
    margin-bottom: 20px;
  }
  .h2 {
    position: relative;
    padding-left: 12px;
    margin-bottom: 32px;
    @media screen and (min-width: 1440px) {
      margin-bottom: 30px;
    }
  }

  .h2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 36px;
    background-color: $main-blue;
    transition: all 1s;
  }
  .full {
    position: relative;
  }
  .mb-desk {
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  .mb {
    margin-bottom: 20px;
  }
  .servicesForList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;

    li {
      width: 44%;
      margin-bottom: 40px;
      @media screen and (min-width: 768px) {
        width: 20%;
        margin-bottom: 0;
      }

      img {
        margin: 0 auto;
        @media screen and (min-width: 390px) {
          width: 60px;
        }
        @media screen and (min-width: 768px) {
          width: 50px;
          margin-bottom: 15px;
        }
      }

      .h4 {
        text-align: center;
      }
    }

    @media screen and (min-width: 768px) {
      flex-wrap: nowrap;
      width: 68%;
    }
    @media screen and (min-width: 1440px) {
      width: 100%;
    }
  }

  .servicesForList li:nth-last-of-type(-n + 2) {
    margin-bottom: 0;
  }

  .servicesForList li:first-child {
    @media screen and (min-width: 768px) {
      order: 1;
    }
  }

  .servicesForList li:nth-child(2) {
    @media screen and (min-width: 768px) {
      order: 2;
    }
  }

  .servicesForList li:nth-child(3) {
    @media screen and (min-width: 768px) {
      order: 4;
    }
  }

  .servicesForList li:nth-child(4) {
    @media screen and (min-width: 768px) {
      order: 3;
    }
  }
  .imgs-container {
    @media screen and (min-width: 1440px) {
      position: absolute;
      right: 0;
      .compass {
        position: absolute;
        top: -120px;
        left: -100px;
      }
    }
  }
  .servicesFor.container {
    @media screen and (min-width: 1440px) {
      width: 100%;
      margin-top: 45px;
    }
  }
  .au-content {
    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .au-text {
    width: 80%;
    display: block;
    margin: 0 auto;

    @media screen and (min-width: 1440px) {
      display: flex;
      flex-direction: column;
    }
    @media screen and (min-width: 1920px) {
      width: 75%;
    }
  }
}
</style>

<style lang="scss">
#about {

  .vueperslide.vueperslide--visible {
    width: 33.3% !important;
  }

  .vueperslides__arrow  svg{
    color: #15263c!important;
  }
}
</style>
